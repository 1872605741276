import { ThemeContext } from "../../../providers/theme";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import React from "react";
import SelectInput from "../../inputs/SelectInput";
import axios from "axios";
import { API_BACKEND } from "../../../config";
import { sectionTypeOptions } from "../../section/sectionTypes";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const ModalContainer = styled.section`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 600px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

// TODO: Change link section selection strategy

export default function MenuItemModal({editData, addItem, editItem, handleClose, parentMenuIndex }) {
  const intl = useIntl();
  const theme = React.useContext(ThemeContext);
  const [menuData, setMenuData] = useState(editData ? editData.menuData : {
    name: "",
    link: "",
    newTab: false,
    submenus: [],
  });

  const [pagesOpt, setPagesOpt] = useState(null);

  const [sectionsOpt, setSectionsOpt] = useState(null);

  const [linkType, setLinkType] = useState(editData ? editData.menuData?.linkType : null);

  const [page, setPage] = useState(editData ? editData.menuData?.page : null);

  const [section, setSection] = useState(editData ? editData.menuData?.section : null);

  const linkTypeOpt = [
    {
      label: intl.formatMessage({ id: "internal link" }),
      value: "internal",
    },
    {
      label: intl.formatMessage({ id: "external link" }),
      value: "external",
    },
    {
      label: intl.formatMessage({ id: "section link" }),
      value: "section",
    },
  ];

  const handleChangeCheck = (e) => {
    const { name, checked } = e.target;
    setMenuData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleChangeLinkType = (name, value) => {
    setPage(null);
    setLinkType(value);
    setMenuData((prevState) => ({...prevState, link: ""}))
  };

  const handleChangePage = (name, value) => {
    setPage(value);
    setSectionsOpt(null);
    setMenuData((prevState) => ({
      ...prevState,
      link: value.value,
    }))
  };

  const handleChangeSection = (name, value) => {
    setSection(value);
    setMenuData((prevState) => {
      return {
      ...prevState,
      link: '#'+value.value,
    }})
  };

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setMenuData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddItem = () => {
    addItem({
      ...menuData,
      linkType,
      page,
      section
    }, parentMenuIndex);
    handleClose();
  };

  const handleEditItem = () => {
    editItem({
      ...menuData,
      linkType,
      page,
      section
    }, parentMenuIndex, editData.index);
    handleClose();
  }

  useEffect(() => {
    if (pagesOpt === null) {
      axios.get(`${API_BACKEND}/pages`).then((res) => {
        const resData = res.data;
        setPagesOpt(
          resData.map((page) => ({ label: page.name, value: page.fullpath })),
        );
      });
    }
  }, [pagesOpt]);

  return (
    <ModalContainer>
      <Title>Menu</Title>
      <StyledTextfield
        id="name"
        name="name"
        onChange={onChange}
        value={menuData?.name}
        label={intl.formatMessage({ id: "name" })}
        variant="outlined"
        width="100%"
        margin="normal"
        size="small"
        required
      />
      <SelectInput
        data={linkTypeOpt}
        value={linkType}
        fieldlabel="label"
        fieldvalue="value"
        label={intl.formatMessage({ id: "select link type" })}
        name="linkType"
        onChange={handleChangeLinkType}
        width="100%"
        margin="normal"
        size="small"
        required
      />
      {pagesOpt !== null && (linkType?.value === "internal")  ? (
        <SelectInput
          data={pagesOpt}
          value={page}
          fieldlabel="label"
          fieldvalue="value"
          label={intl.formatMessage({ id: "select page" })}
          name="page"
          onChange={handleChangePage}
          width="100%"
          margin="normal"
          size="small"
          required
        />
      ) : null}
      {linkType?.value === "section" ? (
        <SelectInput
          data={sectionTypeOptions.map((sectionType) => ({label: sectionType.name, value: sectionType.id}) )}
          value={section}
          fieldlabel="label"
          fieldvalue="value"
          label={intl.formatMessage({ id: "select section" })}
          name="section"
          onChange={handleChangeSection}
          width="100%"
          margin="normal"
          size="small"
          required
        />
      ) : null}
      {linkType?.value === "external"  ? (
        <>
      <StyledTextfield
        id="link"
        name="link"
        onChange={onChange}
        value={menuData?.link}
        label={intl.formatMessage({ id: "link" })}
        variant="outlined"
        width="100%"
        margin="normal"
        size="small"
        required
      />
        <FormControlLabel
          control={
            <Checkbox
              name="newTab"
              checked={menuData?.newTab}
              onChange={handleChangeCheck}
              inputProps={{ "aria-label": "new tab" }}
            />
          }
          label={intl.formatMessage({ id: "new tab" })}
        />
      </>
      ) : null}
      <Button
        onClick={!!editData ? handleEditItem : handleAddItem}
        bg={theme.palette.accent}
        color={theme.palette.base}
      >
        {theme.icons.check}
      </Button>
    </ModalContainer>
  );
}
